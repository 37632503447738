import './App.css';
import { HelmetProvider } from 'react-helmet-async';
import Landing from './Landing';

// Ref: https://www.freecodecamp.org/news/react-helmet-examples/

function App() {
  return (
    <HelmetProvider>
      <Landing/>
    </HelmetProvider>
  );
}

export default App;
