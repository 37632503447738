import React from 'react';
import { Helmet } from 'react-helmet-async';
import feLogo from "./imgs/fe-logo.png";
import downloadAppStore from "./imgs/download_apple.svg";

import fecUI from "./imgs/fec-ui.png";
import './fonts.css';

export default function Landing() {

    // Presentation Logic
    const handleDownload = () => {
      window.open('https://apps.apple.com/app/fineear-chord/id6454346027', '_blank');
    };

  return (

  <React.Fragment>

    {/* Helmet SEO code */}
    <Helmet>
      <title>FineEar~Chord</title>
      <meta name='description' 
            content='Refine the best instrument you have as a musician: Your own ear. Download the iOS app and start to train your ear!' 
      />
    </Helmet>

    {/* The App */}
    <div className="App">

        {/* FE Logo goes here */}
        <img src={feLogo} alt="fine-ear-logo" width="200" height="200" />

        {/* FE Text goes here */}
        <h1>FineEar~Chord</h1>

        {/* FE Marketing text goes here */}
        <h2>Refine the best instrument you have as a musician:</h2>
        <h2>Your own ear</h2>

        {/* FE UI screenshot goes here */}
        <br></br>
        <div className="centered-image">
          <img src={fecUI} alt="fine-ear-chord-ui" width="300" />
        </div>

        <br></br>
        <br></br>
        <img src={downloadAppStore} width="142" height="48" alt="download-app-store" onClick={handleDownload} />

        <br></br>
        {/* FE Logo contact info goes here */}
        <section id="contact">
          <p>Comments? Suggestions?</p>
          <p>→ luko.web@gmail.com ←</p>
        </section>

        <br></br>
        <hr />
        <br></br>

        <footer>Privacy Policy: This app does not collect user data</footer>
    </div>

  </React.Fragment>
  )
}